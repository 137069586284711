import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        resource: 'Dashboard',
        contentWidth: 'full',
        action: 'read',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/widget/:id',
      name: 'stream-alert-widget',
      component: () => import('@/views/widget/StreamAlert.vue'),
      meta: {
        pageTitle: 'Stream Alert Widget',
        resource: 'User',
        action: 'read',
        layout: 'full',
      },
    },
    {
      path: '/widget/:id/donation-goal',
      name: 'donation-overlay-widget',
      component: () => import('@/views/widget/DonationOverlay.vue'),
      meta: {
        pageTitle: 'Donation Overlay Widget',
        resource: 'User',
        action: 'read',
        layout: 'full',
      },
    },
    {
      path: '/widget/:id/media-share',
      name: 'media-share-widget',
      component: () => import('@/views/widget/MediaShare.vue'),
      meta: {
        pageTitle: 'Media Share Widget',
        resource: 'User',
        action: 'read',
        layout: 'full',
      },
    },
    {
      path: '/Game',
      name: 'Game',
      component: () => import('@/views/Game.vue'),
      meta: {
        pageTitle: 'Game',
        resource: 'Game',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Game',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Game/create',
      name: 'Game-create',
      component: () => import('@/views/GameCreate.vue'),
      meta: {
        pageTitle: 'Create Game',
        resource: 'Game',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Game',
            to: '/Game',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Game/:id',
      name: 'Game-edit',
      component: () => import('@/views/GameView.vue'),
      meta: {
        pageTitle: 'View game',
        resource: 'Game',
        action: 'read',
        breadcrumb: [
          {
            text: 'Game',
            to: '/Game',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/Game/:id/edit',
      name: 'game',
      component: () => import('@/views/GameEdit.vue'),
      meta: {
        pageTitle: 'Edit Game',
        resource: 'Game',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Game',
            to: '/Game',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category',
      name: 'category',
      component: () => import('@/views/Category.vue'),
      meta: {
        pageTitle: 'Category',
        resource: 'Category',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category/create',
      name: 'category-create',
      component: () => import('@/views/CategoryCreate.vue'),
      meta: {
        pageTitle: 'Create Category',
        resource: 'Category',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Category',
            to: '/category',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category/:id/edit',
      name: 'category-edit',
      component: () => import('@/views/CategoryEdit.vue'),
      meta: {
        pageTitle: 'Edit Category',
        resource: 'Category',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Category',
            to: '/category',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/service',
      name: 'service',
      component: () => import('@/views/Service.vue'),
      meta: {
        pageTitle: 'Service',
        resource: 'Service',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Service',
            active: true,
          },
        ],
      },
    },
    {
      path: '/service/:id/edit',
      name: 'service-edit',
      component: () => import('@/views/ServiceEdit.vue'),
      meta: {
        pageTitle: 'Edit Service',
        resource: 'Service',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Service',
            to: '/service',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/service/:id',
      name: 'service-view',
      component: () => import('@/views/ServiceView.vue'),
      meta: {
        pageTitle: 'View Service',
        resource: 'Service',
        action: 'read',
        breadcrumb: [
          {
            text: 'Service',
            active: '/service',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post',
      name: 'post',
      component: () => import('@/views/Post.vue'),
      meta: {
        pageTitle: 'Post',
        resource: 'Post',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Post',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-comments/:id',
      name: 'post-comments',
      component: () => import('@/views/PostComments.vue'),
      meta: {
        pageTitle: 'PostComments',
        resource: 'PostComments',
        action: 'read',
        breadcrumb: [
          {
            text: 'PostComments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-likes/:id',
      name: 'post-likes',
      component: () => import('@/views/PostLikes.vue'),
      meta: {
        pageTitle: 'PostLikes',
        resource: 'PostLikes',
        action: 'read',
        breadcrumb: [
          {
            text: 'PostLikes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/post-shares/:id',
      name: 'post-shares',
      component: () => import('@/views/PostShares.vue'),
      meta: {
        pageTitle: 'PostShares',
        resource: 'PostShares',
        action: 'read',
        breadcrumb: [
          {
            text: 'PostShares',
            active: true,
          },
        ],
      },
    },
    {
      path: '/referral',
      name: 'referral',
      component: () => import('@/views/Referral.vue'),
      meta: {
        pageTitle: 'Referral',
        resource: 'Referral',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Referral',
          },
        ],
      },
    },
    {
      path: '/transaction',
      name: 'transaction',
      component: () => import('@/views/Transaction.vue'),
      meta: {
        pageTitle: 'All transactions',
        resource: 'Transaction',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Transaction',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transaction-v2',
      name: 'transaction-v2',
      component: () => import('@/views/TransactionV2.vue'),
      meta: {
        pageTitle: 'All transactions',
        resource: 'Transaction',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'All transactions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/withdrawal-v2',
      name: 'withdrawal-v2',
      component: () => import('@/views/WithdrawalV2.vue'),
      meta: {
        pageTitle: 'Withdrawal',
        resource: 'Withdrawal',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Withdrawal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-v2',
      name: 'order-v2',
      component: () => import('@/views/OrderV2.vue'),
      meta: {
        pageTitle: 'Order V2',
        resource: 'Order',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Order',
            active: true,
          },
        ],
      },
    },
    {
      path: '/donation-v2',
      name: 'donation-v2',
      component: () => import('@/views/DonationV2.vue'),
      meta: {
        pageTitle: 'Donation',
        resource: 'Donation',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Donation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription-v2',
      name: 'subscription-v2',
      component: () => import('@/views/SubscriptionV2.vue'),
      meta: {
        pageTitle: 'Subscription',
        resource: 'Subscription',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription-v2/:id',
      name: 'subscription-v2-view',
      component: () => import('@/views/SubscriptionViewV2.vue'),
      meta: {
        pageTitle: 'Subscription',
        resource: 'Subscription',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription-v2/:id/transaction',
      name: 'subscription-transaction',
      component: () => import('@/views/SubscriptionTransaction.vue'),
      meta: {
        pageTitle: 'Subscription transactions',
        resource: 'Transaction',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Subscription',
          },
          {
            text: 'Transaction',
            active: true,
          },
        ],
      },
    },
    {
      path: '/creator-plan-v2',
      name: 'creator-plan-v2',
      component: () => import('@/views/CreatorPlanV2.vue'),
      meta: {
        pageTitle: 'Creator Plan',
        resource: 'CreatorPlan',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Creator Plan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/creator-plan-v2/:id',
      name: 'creator-plan-v2-view',
      component: () => import('@/views/CreatorPlanViewV2.vue'),
      meta: {
        pageTitle: 'Creator Plan',
        resource: 'CreatorPlan',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Creator Plan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/creator-plan-v2/:id/transaction',
      name: 'creator-plan-transaction',
      component: () => import('@/views/CreatorPlanTransaction.vue'),
      meta: {
        pageTitle: 'Creator Plan Transactions',
        resource: 'Transaction',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Creator Plan',
          },
          {
            text: 'Transaction',
            active: true,
          },
        ],
      },
    },
    {
      path: '/topup',
      name: 'topup',
      component: () => import('@/views/Topup.vue'),
      meta: {
        pageTitle: 'Top Up',
        resource: 'Topup',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Topup',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pay-per-view',
      name: 'pay-per-view',
      component: () => import('@/views/PayPerView.vue'),
      meta: {
        pageTitle: 'Pay Per View',
        resource: 'PayPerView',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Pay Per View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transaction-discrepancy',
      name: 'transaction-discrepancy',
      component: () => import('@/views/Discrepancy.vue'),
      meta: {
        pageTitle: 'Transaction Discrepancy',
        resource: 'Transaction',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Transaction Discrepancy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-transaction/:id',
      name: 'user-transaction',
      component: () => import('@/views/UserTransaction.vue'),
      meta: {
        pageTitle: 'User transactions',
        resource: 'UserTransaction',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'UserTransaction',
            active: true,
          },
        ],
      },
    },
    {
      path: '/transaction/:id',
      name: 'transaction-view',
      component: () => import('@/views/TransactionView.vue'),
      meta: {
        pageTitle: 'View transactions',
        resource: 'Transaction',
        action: 'read',
        breadcrumb: [
          {
            text: 'Transaction',
            active: '/transaction',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/withdrawal',
      name: 'withdrawal',
      component: () => import('@/views/Withdrawal.vue'),
      meta: {
        pageTitle: 'Withdrawal',
        resource: 'Withdrawal',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Withdrawal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/withdrawal/:id',
      name: 'withdrawal-view',
      component: () => import('@/views/WithdrawalView.vue'),
      meta: {
        pageTitle: 'View Withdrawals',
        resource: 'Withdrawal',
        action: 'read',
        breadcrumb: [
          {
            text: 'Withdrawal',
            active: '/withdrawal',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/withdrawal-account-list',
      name: 'withdrawal-account-list',
      component: () => import('@/views/WithdrawalAccountList.vue'),
      meta: {
        pageTitle: 'Withdrawal Account List',
        resource: 'WithdrawalAccountList',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Withdrawal Account List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/voucher',
      name: 'voucher',
      component: () => import('@/views/Voucher.vue'),
      meta: {
        pageTitle: 'Voucher',
        resource: 'Voucher',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Voucher',
            active: true,
          },
        ],
      },
    },
    {
      path: '/voucher/create',
      name: 'voucher-create',
      component: () => import('@/views/VoucherCreate.vue'),
      meta: {
        pageTitle: 'Create Voucher',
        resource: 'Voucher',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Voucher',
            to: '/voucher',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/voucher/:id',
      name: 'voucher-view',
      component: () => import('@/views/VoucherView.vue'),
      meta: {
        pageTitle: 'View Voucher',
        resource: 'Voucher',
        action: 'read',
        breadcrumb: [
          {
            text: 'Edit Voucher',
            active: '/voucher',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/voucher/:id/edit',
      name: 'voucher-edit',
      component: () => import('@/views/VoucherEdit.vue'),
      meta: {
        pageTitle: 'Voucher',
        resource: 'Voucher',
        action: 'read',
        breadcrumb: [
          {
            text: 'Voucher',
            active: '/voucher',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/voucher/:id/usage',
      name: 'voucher-usage',
      component: () => import('@/views/VoucherUsage.vue'),
      meta: {
        pageTitle: 'Voucher Usage',
        resource: 'Voucher',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Voucher',
            to: '/voucher',
          },
          {
            text: ':id',
            to: '/voucher/:id',
          },
          {
            text: 'Usage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/Subscription.vue'),
      meta: {
        pageTitle: 'Subscription',
        resource: 'Subscription',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/creator-plan',
      name: 'creator-plan',
      component: () => import('@/views/CreatorPlan.vue'),
      meta: {
        pageTitle: 'Creator plan',
        resource: 'CreatorPlan',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Creator plan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/donation',
      name: 'donation',
      component: () => import('@/views/Donation.vue'),
      meta: {
        pageTitle: 'Donation',
        resource: 'Donation',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Donation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/subscription/:id',
      name: 'subscription-view',
      component: () => import('@/views/SubscriptionView.vue'),
      meta: {
        pageTitle: 'View Subscription',
        resource: 'Subscription',
        action: 'read',
        breadcrumb: [
          {
            text: 'Subscription',
            to: '/subscription',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/Order3.vue'),
      meta: {
        pageTitle: 'Order',
        resource: 'Order',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Order',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order/:id',
      name: 'order-view',
      component: () => import('@/views/Order3View.vue'),
      meta: {
        pageTitle: 'View Order',
        resource: 'Order',
        action: 'read',
        breadcrumb: [
          {
            text: 'Order',
            to: '/order',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/credit-package',
      name: 'credit-package',
      component: () => import('@/views/CreditPackage.vue'),
      meta: {
        pageTitle: 'Credit Package',
        resource: 'CreditPackage',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Credi tPackage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/credit-package/create',
      name: 'credit-package-create',
      component: () => import('@/views/CreditPackageCreate.vue'),
      meta: {
        pageTitle: 'Credit Package',
        resource: 'CreditPackage',
        action: 'read',
        breadcrumb: [
          {
            text: 'Credit Package',
            to: '/credit-package',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/credit-package/:id/edit',
      name: 'credit-package-edit',
      component: () => import('@/views/CreditPackageEdit.vue'),
      meta: {
        pageTitle: 'Credit Package',
        resource: 'CreditPackage',
        action: 'read',
        breadcrumb: [
          {
            text: 'Credit Package',
            to: '/credit-package',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/credit-package/:id',
      name: 'credit-package-view',
      component: () => import('@/views/CreditPackageView.vue'),
      meta: {
        pageTitle: 'View Credit Package',
        resource: 'CreditPackage',
        action: 'read',
        breadcrumb: [
          {
            text: 'Credit Package',
            active: '/credit-package',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/User.vue'),
      meta: {
        pageTitle: 'User',
        resource: 'User',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/:id/edit',
      name: 'user-edit',
      component: () => import('@/views/UserEdit.vue'),
      meta: {
        pageTitle: 'Edit user',
        resource: 'User',
        action: 'manage',
        breadcrumb: [
          {
            text: 'User',
            to: '/user',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/create',
      name: 'user-create',
      component: () => import('@/views/UserCreate.vue'),
      meta: {
        pageTitle: 'Create user',
        resource: 'User',
        action: 'manage',
        breadcrumb: [
          {
            text: 'User',
            to: '/user',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/:id',
      name: 'user-view',
      component: () => import('@/views/UserView.vue'),
      meta: {
        pageTitle: 'View user',
        resource: 'User',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'User',
            to: '/user',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/manual-profile-review',
      name: 'manual-profile-review',
      component: () => import('@/views/ManualProfileReview.vue'),
      meta: {
        pageTitle: 'Manual Profile Reviewing',
        resource: 'ManualProfileReview',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Manual Profile Reviewing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wallet',
      name: 'wallet',
      component: () => import('@/views/Wallet.vue'),
      meta: {
        pageTitle: 'Wallet',
        resource: 'Wallet',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Wallet',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wallet/:id',
      name: 'wallet-view',
      component: () => import('@/views/WalletView.vue'),
      meta: {
        pageTitle: 'View Wallet',
        resource: 'Wallet',
        action: 'read',
        breadcrumb: [
          {
            text: 'Wallet',
            to: '/wallet',
          },
          {
            text: 'View',
          },
        ],
      },
    },
    {
      path: '/transfer',
      name: 'transfer-wallet',
      component: () => import('@/views/TransferWallet.vue'),
      meta: {
        pageTitle: 'Transfer',
        resource: 'TransferWallet',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Transfer',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event',
      name: 'event',
      component: () => import('@/views/Event.vue'),
      meta: {
        pageTitle: 'Event',
        resource: 'Event',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event/create',
      name: 'event-create',
      component: () => import('@/views/EventCreate.vue'),
      meta: {
        pageTitle: 'Create Event',
        resource: 'Event',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Event',
            to: '/event',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event/:id/edit',
      name: 'event-edit',
      component: () => import('@/views/EventEdit.vue'),
      meta: {
        pageTitle: 'Edit Event',
        resource: 'Event',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Event',
            to: '/event',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event/:id',
      name: 'event-view',
      component: () => import('@/views/EventView.vue'),
      meta: {
        pageTitle: 'View Event',
        resource: 'Event',
        action: 'read',
        breadcrumb: [
          {
            text: 'Event',
            to: '/event',
          },
          {
            text: 'View',
          },
        ],
      },
    },
    {
      path: '/review',
      name: 'review',
      component: () => import('@/views/Review.vue'),
      meta: {
        pageTitle: 'Review',
        resource: 'Review',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Review',
            active: true,
          },
        ],
      },
    },
    {
      path: '/review/:id',
      name: 'review-view',
      component: () => import('@/views/ReviewView.vue'),
      meta: {
        pageTitle: 'View Review',
        resource: 'Review',
        action: 'read',
        breadcrumb: [
          {
            text: 'Review',
            to: '/review',
          },
          {
            text: 'View',
          },
        ],
      },
    },
    {
      path: '/multiple-section',
      name: 'multiple-section',
      component: () => import('@/views/MultipleSection.vue'),
      meta: {
        pageTitle: 'Multiple Section',
        resource: 'MultipleSection',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Multiple Section',
            active: true,
          },
        ],
      },
    },
    {
      path: '/multiple-game',
      name: 'multiple-game',
      component: () => import('@/views/MultipleGame2.vue'),
      meta: {
        pageTitle: 'Multiple Game',
        resource: 'MultipleGame',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Multiple Game',
            active: true,
          },
        ],
      },
    },
    {
      path: '/multiple-section/create',
      name: 'multiple-section-create',
      component: () => import('@/views/MultipleSectionCreate.vue'),
      meta: {
        pageTitle: 'Create Multiple Section',
        resource: 'MultipleSection',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Multiple Section',
            to: '/multiple-section',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/interest',
      name: 'interest',
      component: () => import('@/views/Interest.vue'),
      meta: {
        pageTitle: 'Interest',
        resource: 'Interest',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Interest',
            active: true,
          },
        ],
      },
    },
    {
      path: '/interest/create',
      name: 'interest-create',
      component: () => import('@/views/InterestCreate.vue'),
      meta: {
        pageTitle: 'Create Interest',
        resource: 'Interest',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Interest',
            to: '/interest',
          },
          {
            text: 'Interest',
            active: true,
          },
        ],
      },
    },
    {
      path: '/interest/:id/edit',
      name: 'interest-edit',
      component: () => import('@/views/InterestEdit.vue'),
      meta: {
        pageTitle: 'Edit Interest',
        resource: 'Interest',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Interest',
            to: '/interest',
          },
          {
            text: 'Interest',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile-border',
      name: 'profile-border',
      component: () => import('@/views/ProfileBorder.vue'),
      meta: {
        pageTitle: 'Profile Border',
        resource: 'ProfileBorder',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Profile Border',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile-border/create',
      name: 'profile-border-create',
      component: () => import('@/views/ProfileBorderCreate.vue'),
      meta: {
        pageTitle: 'Create Profile Border',
        resource: 'ProfileBorder',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Profile Border',
            to: '/profile-border',
          },
          {
            text: 'Border',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile-border/:id/edit',
      name: 'profile-border-edit',
      component: () => import('@/views/ProfileBorderEdit.vue'),
      meta: {
        pageTitle: 'Edit Profile Border',
        resource: 'ProfileBorder',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Profile Border',
            to: '/profile-border',
          },
          {
            text: 'Border',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profanity',
      name: 'profanity',
      component: () => import('@/views/Profanity.vue'),
      meta: {
        pageTitle: 'Profanity',
        resource: 'Profanity',
        action: 'read',
        breadcrumb: [
          {
            text: 'Profanity',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/profanity/create',
    //   name: 'profanity-create',
    //   component: () => import('@/views/ProfanityCreate.vue'),
    //   meta: {
    //     pageTitle: 'Create Profanity',
    //     resource: 'Profanity',
    //     action: 'manage',
    //     breadcrumb: [
    //       {
    //         text: 'Profanity',
    //         to: '/profanity',
    //       },
    //       {
    //         text: 'Profanity',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/profanity/:id/edit',
    //   name: 'profanity-edit',
    //   component: () => import('@/views/ProfanityEdit.vue'),
    //   meta: {
    //     pageTitle: 'Edit Profanity',
    //     resource: 'Profanity',
    //     action: 'manage',
    //     breadcrumb: [
    //       {
    //         text: 'Profanity',
    //         to: '/profanity',
    //       },
    //       {
    //         text: 'Profanity',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/highlight',
      name: 'highlight',
      component: () => import('@/views/Highlight.vue'),
      meta: {
        pageTitle: 'Highlight',
        resource: 'Highlight',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Highlight',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gamer-spotlight',
      name: 'gamer-spotlight',
      component: () => import('@/views/GamerSpotlight.vue'),
      meta: {
        pageTitle: 'Gamer Spotlight',
        resource: 'GamerSpotlight',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Gamer Spotlight',
            active: true,
          },
        ],
      },
    },
    {
      path: '/multiple-game/create',
      name: 'multiple-game-create',
      component: () => import('@/views/MultipleGameCreate.vue'),
      meta: {
        pageTitle: 'Create Multiple Game',
        resource: 'MultipleGame',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Multiple Game',
            to: '/multiple-game',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/multiple-section/:id/edit',
      name: 'multiple-section-edit',
      component: () => import('@/views/MultipleSectionEdit.vue'),
      meta: {
        pageTitle: 'Edit Multiple Section',
        resource: 'MultipleSection',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Multiple Section',
            to: '/multiple-section',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/highlight/create',
      name: 'highlight-create',
      component: () => import('@/views/HighlightCreate.vue'),
      meta: {
        pageTitle: 'Create Highlight',
        resource: 'Highlight',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Highlight',
            to: '/highlight',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/highlight/:id/edit',
      name: 'highlight-edit',
      component: () => import('@/views/HighlightEdit.vue'),
      meta: {
        pageTitle: 'Edit Highlight',
        resource: 'Highlight',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Highlight',
            to: '/highlight',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/multiple-section/:id',
      name: 'multiple-section-view',
      component: () => import('@/views/MultipleSectionView.vue'),
      meta: {
        pageTitle: 'View Multiple Section',
        resource: 'MultipleSection',
        action: 'read',
        breadcrumb: [
          {
            text: 'Multiple Section',
            to: '/multiple-section',
          },
          {
            text: 'View',
          },
        ],
      },
    },
    {
      path: '/multiple-game/:id/edit',
      name: 'multiple-game-edit',
      component: () => import('@/views/MultipleGameEdit.vue'),
      meta: {
        pageTitle: 'Edit Multiple Game',
        resource: 'MultipleGame',
        action: 'read',
        breadcrumb: [
          {
            text: 'Multiple Game',
            to: '/multiple-game',
          },
          {
            text: 'View',
            active: true,
          },
        ],
      },
    },
    {
      path: '/multiple-game/:id',
      name: 'multiple-game-view',
      component: () => import('@/views/MultipleGameView.vue'),
      meta: {
        pageTitle: 'View Multiple Game',
        resource: 'MultipleGame',
        action: 'read',
        breadcrumb: [
          {
            text: 'Multiple Game',
            to: '/multiple-game',
          },
          {
            text: 'View',
          },
        ],
      },
    },
    {
      path: '/highlight/:id',
      name: 'highlight-view',
      component: () => import('@/views/HighlightView.vue'),
      meta: {
        pageTitle: 'View Highlight',
        resource: 'Highlight',
        action: 'read',
        breadcrumb: [
          {
            text: 'Highlight',
            to: '/highlight',
          },
          {
            text: 'View',
          },
        ],
      },
    },
    {
      path: '/banner',
      name: 'banner',
      component: () => import('@/views/Banner.vue'),
      meta: {
        pageTitle: 'Banner',
        resource: 'Banner',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Banner',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner/create',
      name: 'banner-create',
      component: () => import('@/views/BannerCreate.vue'),
      meta: {
        pageTitle: 'Create Banner',
        resource: 'Banner',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Banner',
            to: '/banner',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/banner/:id/edit',
      name: 'banner-edit',
      component: () => import('@/views/BannerEdit.vue'),
      meta: {
        pageTitle: 'Edit Banner',
        resource: 'Banner',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Banner',
            to: '/banner',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms',
      name: 'cms',
      component: () => import('@/views/Cms.vue'),
      meta: {
        pageTitle: 'CMS',
        resource: 'Cms',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'CMS',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms/create',
      name: 'cms-create',
      component: () => import('@/views/CmsCreate.vue'),
      meta: {
        pageTitle: 'CMS',
        resource: 'Cms',
        action: 'read',
        breadcrumb: [
          {
            text: 'CMS',
            to: '/cms',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cms/:id/edit',
      name: 'cms-edit',
      component: () => import('@/views/CmsEdit.vue'),
      meta: {
        pageTitle: 'CMS',
        resource: 'Cms',
        action: 'read',
        breadcrumb: [
          {
            text: 'CMS',
            to: '/cms',
          },
          {
            text: 'edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/show-reel',
      name: 'show-reel',
      component: () => import('@/views/ShowReel.vue'),
      meta: {
        pageTitle: 'Show Reels',
        resource: 'ShowReel',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Show Reels',
            active: true,
          },
        ],
      },
    },
    {
      path: '/app-setting',
      name: 'app-setting',
      component: () => import('@/views/SettingApp.vue'),
      meta: {
        pageTitle: 'App Setting',
        resource: 'AppSetting',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'App Setting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/exchange-rate',
      name: 'exchange-rate',
      component: () => import('@/views/ExchangeRate.vue'),
      meta: {
        pageTitle: 'Exchange Rate',
        resource: 'ExchangeRate',
        action: 'read',
        breadcrumb: [
          {
            text: 'Exchange Rate',
            active: true,
          },
        ],
      },
    },
    {
      path: '/processing-fee',
      name: 'processing-fee',
      component: () => import('@/views/ProcessingFees.vue'),
      meta: {
        pageTitle: 'ProcessingFee',
        resource: 'ProcessingFee',
        action: 'read',
        breadcrumb: [
          {
            text: 'ProcessingFee',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activity-log',
      name: 'activity-log',
      component: () => import('@/views/ActivityLog.vue'),
      meta: {
        pageTitle: 'Activity Log',
        resource: 'ActivityLog',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Activity Log',
            active: true,
          },
        ],
      },
    },
    {
      path: '/export-result',
      name: 'export-result',
      component: () => import('@/views/ExportResult.vue'),
      meta: {
        pageTitle: 'Exported Data',
        resource: 'ExportResult',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Exported Data',
            active: true,
          },
        ],
      },
    },
    {
      path: '/app-setting-edit',
      name: 'app-setting-edit',
      component: () => import('@/views/SettingAppEdit.vue'),
      meta: {
        pageTitle: 'App Setting Edit',
        resource: 'AppSetting',
        action: 'read',
        breadcrumb: [
          {
            text: 'App Setting',
            to: '/app-setting',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/Report.vue'),
      meta: {
        pageTitle: 'Report',
        resource: 'Report',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/finder',
      name: 'finder',
      component: () => import('@/views/UsernameLog.vue'),
      meta: {
        pageTitle: 'User Finder',
        resource: 'UsernameTracker',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'User Finder',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-password',
      name: 'edit-password',
      component: () => import('@/views/EditPassword.vue'),
      meta: {
        pageTitle: 'Edit Password',
        resource: 'EditPassword',
        action: 'read',
        contentWidth: 'full',
        breadcrumb: [
          {
            text: 'Edit Password',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!canNavigate(to) && !isLoggedIn) {
    return next({ name: 'login' })
  }

  // Redirect to home if logged in
  if (!canNavigate(to) && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData.roles))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
