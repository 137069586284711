import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import image from './utils/image'

import dashboard from './dashboard'

import withdrawal from './module/withdrawal'
import user from './module/user'
import Game from './module/game'
import category from './module/category'
import service from './module/service'
import post from './module/post'
import postComments from './module/post-comments'
import postLikes from './module/post-likes'
import postShares from './module/post-shares'
import referral from './module/referral'
import subscription from './module/subscription'
import order from './module/order'
import transaction from './module/transaction'
import voucher from './module/voucher'
import wallet from './module/wallet'
import creditPackage from './module/credit-package'
import event from './module/event'
import review from './module/review'
import languages from './module/languages'
import multipleSection from './module/multiple-section'
import multipleGame from './module/multiple-game'
import highlight from './module/highlight'
import donation from './module/donation'
import creatorPlan from './module/creator-plan'
import cms from './module/cms'
import showReel from './module/show-reel'
import gamerSpotlight from './module/gamer-spotlight'
import voucherUsage from './module/voucher-usage'
import exchangeRate from './module/exchange-rate'
import processingFee from './module/processing-fee'
import setting from './module/setting'
import banner from './module/banner'
import transfer from './module/transfer'
import activityLog from './module/activity-log'
import interest from './module/interest'
import exportResult from './module/export-result'
import profanity from './module/profanity'
import report from './module/report'
import transactionV2 from './module/transactionV2'
import withdrawalV2 from './module/withdrawalV2'
import orderV2 from './module/orderV2'
import donationV2 from './module/donationV2'
import subscriptionV2 from './module/subscriptionV2'
import creatorPlanV2 from './module/creator-planV2'
import discrepancy from './module/discrepancy'
import country from './module/country'
import topup from './module/topup'
import payPerView from './module/pay-per-view'
import border from './module/border'
import manualProfileReview from './module/manual-profile'
import withdrawalAccount from './module/withdrawal-account'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    image,

    dashboard,

    order,
    subscription,
    withdrawal,
    user,
    Game,
    category,
    service,
    post,
    postComments,
    postLikes,
    postShares,
    referral,
    transaction,
    voucher,
    wallet,
    creditPackage,
    event,
    review,
    languages,
    multipleSection,
    multipleGame,
    highlight,
    donation,
    creatorPlan,
    cms,
    showReel,
    gamerSpotlight,
    voucherUsage,
    exchangeRate,
    processingFee,
    setting,
    banner,
    transfer,
    activityLog,
    interest,
    exportResult,
    profanity,
    report,
    transactionV2,
    withdrawalV2,
    orderV2,
    donationV2,
    subscriptionV2,
    creatorPlanV2,
    discrepancy,
    country,
    topup,
    payPerView,
    manualProfileReview,
    withdrawalAccount,
    border,
  },
  strict: process.env.DEV,
})
