import countries from '@/list/countries'
import { withdrawalFilterPayment } from '@/list/payment-method'

export default [
  {
    key: 'owner_user_id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'owner_user_nickname',
    label: 'User',
    type: 'profile',
    image: 'owner_user_avatar',
    sortable: false,
    url: {
      callback(data) {
        return `/user/${data.owner_user_id}`
      },
    },
  },
  {
    key: 'owner_user_email',
    type: 'email',
    label: 'Email',
  },
  {
    key: 'country_code',
    label: 'Country',
    type: 'country',
    filter: {
      key: 'countries',
      type: 'multiple',
      options: countries,
    },
  },
  {
    key: 'owner_user_profile_v2.country_code',
    label: 'User Country',
    type: 'country',
    filter: {
      key: 'userCountries',
      type: 'multiple',
      options: countries,
    },
  },
  {
    key: 'payment_method_data.account_name',
    label: 'Acc. name',
  },
  {
    key: 'payment_method_data.email',
    label: 'Email/Acc. number',
  },
  {
    key: 'verification_url',
    type: 'UploadedIDImage',
    label: 'Uploaded ID',
  },
  {
    key: 'verification_uploaded_at',
    label: 'Uploaded at',
    type: 'datetime',
    filter: {
      key: 'is_verification_submitted',
      type: 'select',
      options: [
        { value: 'true', text: 'Submited Verification' },
        { value: 'false', text: 'Unverified' },
      ],
    },
  },
  {
    key: 'verified',
    label: 'Verified ID',
    type: 'verified',
    sortable: false,
  },
  {
    key: 'verification_at',
    label: 'Verified at',
    type: 'datetime',
    filter: {
      key: 'is_verified',
      type: 'select',
      options: [
        { value: 'true', text: 'Verified Acc' },
        { value: 'false', text: 'Unverified Acc' },
      ],
    },
  },
  withdrawalFilterPayment,
]
