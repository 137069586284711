import { get, set } from '@/helper/localStorage'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    statisticCountData: get('statisticCountData', []),
    statisticAmountData: get('statisticAmountData', []),
    statisticRevenueServicesData: get('statisticRevenueServicesData', []),
    statisticRevenueRegionsData: get('statisticRevenueRegionsData', []),
    creatorLeaderboardData: get('creatorLeaderboardData', []),
    topCreatorLeaderboardData: [],
    topCreatorLeaderboardDataLast: [],
    pendingWithdrawalCount: '',
    loadingPendingCount: false,
    loadingStatisticCount: false,
    loadingStatisticAmount: false,
    loadingRevenueServices: false,
    loadingRevenueRegions: false,
    loadingCreatorLeaderboard: false,
    loadingTopCreatorLeaderboard: false,
  },
  getters: {
    countUpdatedAt: state => state.statisticCountData[0].updatedAt,
    amountUpdatedAt: state => state.statisticAmountData[0].updatedAt,
    revenueServicesUpdatedAt: state => state.statisticRevenueServicesData[0].updatedAt,
    revenueRegionsUpdatedAt: state => state.statisticRevenueRegionsData[0].updatedAt,
    xAxisCountLabels: state => state.statisticCountData.map(({ date }) => date),
    xAxisAmountLabels: state => state.statisticAmountData.map(({ date }) => date),
    xAxisRevenueServicesLabels: state => state.statisticRevenueServicesData.map(({ date }) => date),
    xAxisRevenueRegionsLabels: state => state.statisticRevenueRegionsData.map(({ date }) => date),
    transactionCountData: state => state.statisticCountData.map(({ transaction }) => transaction),
    registrationCountData: state => state.statisticCountData.map(({ signup }) => signup),
    donationAmountData: state => state.statisticAmountData.map(({ donation }) => donation),
    subscriptionAmountData: state => state.statisticAmountData.map(({ subscription }) => subscription),
    revenueServicesData: state => (state.statisticRevenueServicesData.length ? [
      {
        name: 'Donation',
        data: state.statisticRevenueServicesData.map(({ donation }) => Math.round(donation)),
      },
      {
        name: 'Membership',
        data: state.statisticRevenueServicesData.map(({ membership }) => Math.round(membership)),
      },
      {
        name: 'Service',
        data: state.statisticRevenueServicesData.map(({ service }) => Math.round(service)),
      },
      {
        name: 'Digital',
        data: state.statisticRevenueServicesData.map(({ digital }) => Math.round(digital)),
      },
      {
        name: 'Physical',
        data: state.statisticRevenueServicesData.map(({ physical }) => Math.round(physical)),
      },
      {
        name: 'PPV',
        data: state.statisticRevenueServicesData.map(({ ppv }) => Math.round(ppv)),
      },
    ] : []),
    revenueRegionsData: state => (state.statisticRevenueRegionsData.length ? [
      {
        name: 'Philippines',
        data: state.statisticRevenueRegionsData.map(({ ph }) => Math.round(ph)),
      },
      {
        name: 'Thailand',
        data: state.statisticRevenueRegionsData.map(({ th }) => Math.round(th)),
      },
      {
        name: 'Malaysia',
        data: state.statisticRevenueRegionsData.map(({ my }) => Math.round(my)),
      },
      {
        name: 'Singapore',
        data: state.statisticRevenueRegionsData.map(({ sg }) => Math.round(sg)),
      },
      {
        name: 'Indonesia',
        data: state.statisticRevenueRegionsData.map(({ id }) => Math.round(id)),
      },
    ] : []),
  },
  mutations: {
    SET_STATISTIC_COUNT_DATA(state, val) {
      // set('statisticCountData', val)
      state.statisticCountData = val
    },
    SET_STATISTIC_AMOUNT_DATA(state, val) {
      set('statisticAmountData', val)
      state.statisticAmountData = val
    },
    SET_STATISTIC_REVENUE_SERVICES(state, val) {
      set('statisticRevenueServicesData', val)
      state.statisticRevenueServicesData = val
    },
    SET_STATISTIC_REVENUE_REGIONS(state, val) {
      set('statisticRevenueRegionsData', val)
      state.statisticRevenueRegionsData = val
    },
    SET_CREATOR_LEADERBOARD(state, val) {
      set('creatorLeaderboardData', val)
      state.creatorLeaderboardData = val
    },
    SET_TOP_CREATOR_LEADERBOARD(state, val) {
      // set('topCreatorLeaderboardData', val)
      state.topCreatorLeaderboardData = val
    },
    SET_TOP_CREATOR_LEADERBOARD_LAST(state, val) {
      state.topCreatorLeaderboardDataLast = val
    },
    SET_PENDING_WITHDRAWAL_COUNT(state, val) {
      state.pendingWithdrawalCount = val
    },
    SET_LOADING_PENDING_COUNT(state, val) {
      state.loadingPendingCount = val
    },
    SET_LOADING_STATISTIC_COUNT(state, val) {
      state.loadingStatisticCount = val
    },
    SET_LOADING_STATISTIC_AMOUNT(state, val) {
      state.loadingStatisticAmount = val
    },
    SET_LOADING_REVENUE_SERVICES(state, val) {
      state.loadingRevenueServices = val
    },
    SET_LOADING_REVENUE_REGIONS(state, val) {
      state.loadingRevenueRegions = val
    },
    SET_LOADING_CREATOR_LEADERBOARD(state, val) {
      state.loadingCreatorLeaderboard = val
    },
    SET_LOADING_TOP_CREATOR_LEADERBOARD(state, val) {
      state.loadingTopCreatorLeaderboard = val
    },
  },
  actions: {
    getStatisticCount({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_STATISTIC_COUNT', true)

      const params = { startedAt, endedAt }

      this._vm.$http.get('/v1/bo/users/statistic', { params, signal })
        .then(({ data }) => {
          commit('SET_STATISTIC_COUNT_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_STATISTIC_COUNT', false)
        })
    },
    getStatisticAmount({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_STATISTIC_AMOUNT', true)

      const params = { startedAt, endedAt }
      this._vm.$http.get('/v1/bo/users/statistic/amount', { params, signal })
        .then(({ data }) => {
          commit('SET_STATISTIC_AMOUNT_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_STATISTIC_AMOUNT', false)
        })
    },
    getCreatorLeaderboard({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_CREATOR_LEADERBOARD', true)

      const params = { startedAt, endedAt }
      this._vm.$http.get('/v1/bo/users/statistic/revenue-leaderboard', { params, signal })
        .then(({ data }) => {
          commit('SET_CREATOR_LEADERBOARD', data.data.slice(0, 10))
        })
        .finally(() => {
          commit('SET_LOADING_CREATOR_LEADERBOARD', false)
        })
    },
    getTopCreatorLeaderboard({ commit }, {
      signal,
      startedAt,
      endedAt,
      limit,
    }) {
      commit('SET_LOADING_TOP_CREATOR_LEADERBOARD', true)

      const params = { startedAt, endedAt, limit }
      this._vm.$http.get('/v1/bo/users/statistic/top-creator', { params, signal })
        .then(({ data }) => {
          // Convert the 'day' field for each record from UTC to local time.
          const localData = data.data.map(item => ({
            ...item,
            day: moment.utc(item.day).local().format('YYYY-MM-DD HH:mm:ss'),
          }))
          commit('SET_TOP_CREATOR_LEADERBOARD', localData.slice(0, limit))
        })
        .finally(() => {
          commit('SET_LOADING_TOP_CREATOR_LEADERBOARD', false)
        })
    },
    getTopCreatorLeaderboardLast({ commit }, {
      signal,
      startedAt,
      endedAt,
      limit,
    }) {
      const params = { startedAt, endedAt, limit }
      this._vm.$http.get('/v1/bo/users/statistic/top-creator', { params, signal })
        .then(({ data }) => {
          // Convert the 'day' field for each record from UTC to local time.
          const localData = data.data.map(item => ({
            ...item,
            day: moment.utc(item.day).local().format('YYYY-MM-DD HH:mm:ss'),
          }))
          commit('SET_TOP_CREATOR_LEADERBOARD_LAST', localData.slice(0, limit))
        })
    },
    getRevenueByServices({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_REVENUE_SERVICES', true)

      const params = { startedAt, endedAt }
      this._vm.$http.get('/v1/bo/users/statistic/revenue', { params, signal })
        .then(({ data }) => {
          commit('SET_STATISTIC_REVENUE_SERVICES', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_REVENUE_SERVICES', false)
        })
    },
    getRevenueByRegions({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_REVENUE_REGIONS', true)

      const params = { startedAt, endedAt }
      this._vm.$http.get('/v1/bo/users/statistic/revenue-country', { params, signal })
        .then(({ data }) => {
          const revenue = []
          for (let index = 0; index < data.data.ID.length; index += 1) {
            const { PH } = data.data
            const { TH } = data.data
            const { MY } = data.data
            const { SG } = data.data
            const { ID } = data.data

            revenue.push({
              date: ID[index].date,
              ph: PH[index].donation + PH[index].membership + PH[index].service + PH[index].digital + PH[index].physical + PH[index].ppv,
              th: TH[index].donation + TH[index].membership + TH[index].service + TH[index].digital + TH[index].physical + TH[index].ppv,
              my: MY[index].donation + MY[index].membership + MY[index].service + MY[index].digital + MY[index].physical + MY[index].ppv,
              sg: SG[index].donation + SG[index].membership + SG[index].service + SG[index].digital + SG[index].physical + SG[index].ppv,
              id: ID[index].donation + ID[index].membership + ID[index].service + ID[index].digital + ID[index].physical + ID[index].ppv,
              updatedAt: ID[index].updatedAt,
            })
          }

          commit('SET_STATISTIC_REVENUE_REGIONS', revenue)
        })
        .finally(() => {
          commit('SET_LOADING_REVENUE_REGIONS', false)
        })
    },
    getPendingCount({ commit }) {
      commit('SET_LOADING_PENDING_COUNT', true)

      this._vm.$http.get('/v1/bo/users/statistic/pending')
        .then(({ data }) => {
          commit('SET_PENDING_WITHDRAWAL_COUNT', data.data.pendingWithdrawal)
        })
        .finally(() => {
          commit('SET_LOADING_PENDING_COUNT', false)
        })
    },
  },
}
