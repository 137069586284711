import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/withdrawalV2'

export default {
  namespaced: true,
  state: {
    tableFields: get('withdrawalV2TableFields', fields),
    tableData: [],
    tableSort: get('withdrawalV2TableSort'),
    tableFilter: get('withdrawalV2TableFilter', []),
    tablePagination: get('withdrawalV2TablePagination'),
    tableKeyword: get('withdrawalV2TableKeyword'),
    selectedIds: get('withdrawalV2SelectedIds', []),
    userData: [],
    loadingTable: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableData: state => state.tableData.map(item => {
      let creator = null

      creator = {
        id: item.seller_user_id || item.usecase_data?.data?.user_id,
        avatar: item.seller_user_avatar_url,
        nickname: item.seller_user_nickname || item.usecase_data?.data?.user_nickname,
      }

      return ({
        ...item,
        id: item.human_id,
        creator,
      })
    }),
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      per_page: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('withdrawalV2TableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('withdrawalV2TableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('withdrawalV2TablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('withdrawalV2TableKeyword', val)
      state.tableKeyword = val
    },
    SET_TABLE_SORT(state, val) {
      set('withdrawalV2TableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('withdrawalV2TableFilter', val)
      state.tableFilter = val
    },
    SET_SELECTED_IDS(state, val) {
      set('withdrawalV2SelectedIds', val)
      state.selectedIds = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    async getTableData({
      state, commit, getters, rootState,
    }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      await this._vm.$http.get('/v2/payment/admin/transactions?usecases=WITHDRAWAL', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', {
            currentPage: state.tablePagination?.currentPage ?? 1,
            totalPage: data.total / (state.tablePagination?.currentPage ?? rootState.appConfig.settings.tablePerPage),
            perPage: state.tablePagination?.per_page ?? rootState.appConfig.settings.tablePerPage,
            totalData: data.total,
          })
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/wallets/withdrawal-requests/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    changeStatus({ commit, dispatch }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/wallets/withdrawal-requests/${id}/statuses`, data)
        .then(() => {
          // reload pending withdrawal counter
          dispatch('dashboard/getPendingCount', {}, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    changeStatusMultiple({ commit, dispatch }, { ids, status, reason }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/wallets/withdrawal-requests/statuses/multiple', {
        ids, status, reason,
      })
        .then(() => {
          // reload pending withdrawal counter
          dispatch('dashboard/getPendingCount', {}, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters

      return this._vm.$http.post('/v1/bo/users/export/withdrawal-requests', {
        ...params,
        statuses: params.statuses ? [params.statuses] : null,
        orderBy: [params.orderBy],
        page: undefined,
        perPage: undefined,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    getUserData({ commit }, keyword = '') {
      if (!keyword) {
        commit('SET_USER_DATA', [])
      }

      return this._vm.$http.get('/v1/bo/users/', {
        params: {
          page: 1,
          perPage: 6,
          keyword,
          userRoles: !keyword ? 'finance' : 'finance,super-admin,administrator,marketer,moderator,customer-support',
        },
      })
        .then(({ data }) => {
          const userData = data.data ? data.data.map(item => ({
            value: item.id,
            text: item.nickname || item.email,
            subtitle: item.nickname ? item.email : undefined,
            image: item.avatar,
          })) : []

          commit('SET_USER_DATA', userData)

          return userData
        })
    },
  },
}
