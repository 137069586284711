import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/withdrawal-account'

export default {
  namespaced: true,
  state: {
    tableFields: get('userTableFields', fields),
    tableData: [],
    tablePagination: get('userTablePagination'),
    tableKeyword: get('userTableKeyword'),
    tableSort: get('userTableSort'),
    tableFilter: get('userTableFilter', []),
    detailData: get('userDetailData'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => {
      if (!state.tableData) {
        return []
      }

      return state.tableData.map(item => ({
        ...item,
        payment: item.payment_method.toLowerCase(),
        // // userInterestsName: item.userInterests.map(({ name }) => name),
        // fullName: [item.firstName, item.lastName]
        //   .filter(name => name)
        //   .join(' ')
        //   .replace('  ', ' '), // remove double space
        // active_status: item.active ? 'active' : 'deactive',
        // verified_status: item.verified ? 'verified' : 'unverified',
        // tags: item.tags || [],
        // isSuspended: (item.isSuspended ?? false) ? 'Suspended' : 'No',
        // profileUrl: `${process.env.VUE_APP_CLIENT_URL}/${item.nickname}`,
      }))
    },
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      const userInterest = _get(state, 'detailData.userInterests') || []

      return {
        ...state.detailData,
        userInterestsName: userInterest.map(({ name }) => name),
        fullName: [_get(state, 'detailData.firstName'), _get(state, 'detailData.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
        active_status: _get(state, 'detailData.active') ? 'active' : 'inactive',
        verified_status: _get(state, 'detailData.verified') ? 'verified' : 'unverified',
        tags: _get(state, 'detailData.tags') || [],
        verified_ID: _get(state, 'detailData.verified') ? 'verified' : 'unverified',
      }
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('userTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('userTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('userTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('userTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('userDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('userTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('userTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    async getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters

      try {
        const { data } = await this._vm.$http.get(
          '/users/withdrawal-accounts',
          {
            baseURL: `${process.env.VUE_APP_API_V2_URL}/payment/admin`,
            params,
          },
        )

        commit('SET_TABLE_DATA', data.data || [])
        commit('SET_TABLE_PAGINATION', {
          currentPage: data?._meta?.page,
          perPage: data?._meta?.per_page,
          totalData: data?._meta?.total_data,
          totalPage: data?._meta?.total_page,
        })
      } catch (error) {
        console.error('Error fetching table data:', error) // eslint-disable-line no-console
      } finally {
        commit('SET_LOADING_TABLE', false)
      }
    },
    async getDetail({ commit }, id) {
      try {
        commit('SET_LOADING_DETAIL', true)

        const res = await this._vm.$http.get(`/v1/bo/users/${id}`)
        commit('SET_DETAIL_DATA', res.data.data)
      } catch (error) {
        console.error('Error fetching detail data:', error) // eslint-disable-line no-console
      } finally {
        commit('SET_LOADING_DETAIL', false)
      }
    },
    async banWithdrawalAccount({ commit }, payload) {
      commit('SET_LOADING_SUBMIT', true)
      return this._vm.$http
        .post('/withdrawal-account/ban', payload, {
          baseURL: `${process.env.VUE_APP_API_V2_URL}/payment/admin`,
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
