import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/withdrawal'

export default {
  namespaced: true,
  state: {
    tableFields: get('withdrawalTableFields', fields),
    tableData: [],
    tableSort: get('withdrawalTableSort'),
    tableFilter: get('withdrawalTableFilter', []),
    tablePagination: get('withdrawalTablePagination'),
    tableKeyword: get('withdrawalTableKeyword'),
    selectedIds: get('withdrawalSelectedIds', []),
    userData: [],
    loadingTable: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('withdrawalTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('withdrawalTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('withdrawalTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('withdrawalTableKeyword', val)
      state.tableKeyword = val
    },
    SET_TABLE_SORT(state, val) {
      set('withdrawalTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('withdrawalTableFilter', val)
      state.tableFilter = val
    },
    SET_SELECTED_IDS(state, val) {
      set('withdrawalSelectedIds', val)
      state.selectedIds = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    async getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      await this._vm.$http.get('/v1/bo/users/withdrawal-requests', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/wallets/withdrawal-requests/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    changeStatus({ commit, dispatch }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/wallets/withdrawal-requests/${id}/statuses`, data)
        .then(() => {
          // reload pending withdrawal counter
          dispatch('dashboard/getPendingCount', {}, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    changeStatusMultiple({ commit, dispatch }, { ids, status, reason }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/wallets/withdrawal-requests/statuses/multiple', {
        ids, status, reason,
      })
        .then(() => {
          // reload pending withdrawal counter
          dispatch('dashboard/getPendingCount', {}, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters
      const payload = {
        ...params,
        searchKeywords: params.keyword,
        statuses: params.statuses ? [params.statuses] : null,
        orderBy: params.orderBy,
        tiers: params.tiers ? params.tiers.split(',') : undefined,
        countries: params.countries ? params.countries.split(',') : undefined,
        userCountries: params.userCountries ? params.userCountries.split(',') : undefined,
        payments: params.payments ? params.payments.split(',') : undefined,
        startedAt: undefined,
        startDate: params.startedAt,
        endedAt: undefined,
        endDate: params.endedAt,
        keywords: undefined,
        perPage: Number(params.perPage),
      }

      return this._vm.$http.post('/v1/bo/users/export/withdrawal-requests', payload)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    getUserData({ commit }, keyword = '') {
      if (!keyword) {
        commit('SET_USER_DATA', [])
      }

      return this._vm.$http.get('/v1/bo/users/', {
        params: {
          page: 1,
          perPage: 6,
          keyword,
          userRoles: !keyword ? 'finance' : 'finance,super-admin,administrator,marketer,moderator,customer-support',
        },
      })
        .then(({ data }) => {
          const userData = data.data ? data.data.map(item => ({
            value: item.id,
            text: item.nickname || item.email,
            subtitle: item.nickname ? item.email : undefined,
            image: item.avatar,
          })) : []

          commit('SET_USER_DATA', userData)

          return userData
        })
    },
    verifyID({ commit, dispatch }, { id, status, reason }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/users/${id}/verify-withdrawal-account`, { userID: id, verified: status, rejectReason: reason })
        .then(() => {
          if (!status) {
            this._vm.$http.post('/v1/wallets/withdrawal-requests/statuses/multiple', {
              userID: id, status: 'rejected', reason,
            })
          }
          // reload pending withdrawal counter
          dispatch('dashboard/getPendingCount', {}, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
