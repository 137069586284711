import { get, set } from '@/helper/localStorage'
import fields from '@/field/border'

export default {
  namespaced: true,
  state: {
    tableFields: [],
    tableData: [],
    detailData: get('borderDetailData'),
    tableFilter: get('borderTableFilter', []),
    tableSort: get('borderTableSort'),
    tablePagination: get('borderTablePagination'),
    tableKeyword: get('borderTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
    })),
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
      }
    },
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('borderTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('borderTableKeyword', val)
      state.tableKeyword = val
    },
    SET_TABLE_SORT(state, val) {
      set('borderTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('borderTableFilter', val)
      state.tableFilter = val
    },
    SET_DETAIL_DATA(state, val) {
      set('borderDetailData', val)
      state.detailData = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    async getBordersSelection() {
      const response = await this._vm.$http.instances.v2.get('user-content/admin/borders')
      if (response && response.data) {
        return response.data
      }

      return []
    },
    getTableData({ commit }) {
      commit('SET_LOADING_TABLE', true)

      this._vm.$http.instances.v2.get('user-content/admin/borders')
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, name) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.instances.v2.get(`/user-content/borders/detail?name=${name}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    getBorderOptions(_, id) {
      return this._vm.$http.instances.v2.get(`/user-content/admin/borders/my-borders?user_id=${id}`)
    },
    assignBorderToUser(_, payload) {
      return this._vm.$http.instances.v2.post('/user-content/admin/borders/assign', payload)
    },
    unassignBorderToUser(_, payload) {
      return this._vm.$http.instances.v2.patch('/user-content/admin/borders/assign', payload)
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)
      return this._vm.$http.instances.v2.post('/user-content/admin/borders', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    update({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      const payload = {
        id: data.name,
        ...data,
      }
      return this._vm.$http.instances.v2.patch('/user-content/admin/borders', payload)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, name) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.instances.v2.post(`/user-content/admin/borders/delete?name=${name}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
