import Vue from 'vue'
import get from 'lodash/get'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { logout } from '@/auth/utils'

// Create a function to generate axios instances with different base URLs
const createAxiosInstance = baseURL => {
  const axiosIns = axios.create({
    baseURL,
    // You can add additional config specific to this instance
    // withCredentials: true,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
  })

  // Add interceptors to the instance
  axiosIns.interceptors.response.use(
    response => response,
    error => {
      const code = parseInt(error.response && error.response.status, 10)
      const message = get(error, 'response.data.errors.message')
      let alertTitle = ''
      let alertMessage = ''

      switch (code) {
        case 401:
          alertTitle = 'Your session has ended'
          alertMessage = 'Please login to continue.'
          if (message === 'invalid challenge input') {
            alertTitle = 'Invalid OTP Code'
            alertMessage = 'Please double-check your code.'
          }
          logout()
          break
        case 403:
          alertTitle = 'Not allowed'
          alertMessage = 'You are not permitted to do this action.'
          break
        case 404:
          alertTitle = 'Data could not be found'
          alertMessage = get(error, 'response.data.errors.message') || 'Please check your request'
          break
        case 422:
          alertTitle = 'Please check Your input'
          try {
            [[alertMessage]] = Object.values(error.response.data.errors.errors)
          } catch {
            alertMessage = 'Invalid input'
          }
          break
        case 500:
          alertTitle = 'Something went wrong'
          alertMessage = error.response.data.errors.message
          break
        default:
          alertTitle = 'Something went wrong'
          alertMessage = get(error, 'response.data.errors.message', 'An unexpected error occurred')
          return Promise.reject(error)
      }

      // If there is message and alert is not hidden
      if (alertMessage && new URL(error.request.responseURL).searchParams.get('_alert') !== 'hide') {
        // Use Vue to show toast
        const vm = new Vue()
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: alertTitle,
            icon: 'BellIcon',
            text: alertMessage,
            variant: 'danger',
          },
        })
      }

      return Promise.reject(error)
    },
  )

  return axiosIns
}

// Create multiple axios instances
const axiosInstances = {
  // Main API instance
  main: createAxiosInstance(process.env.VUE_APP_API_URL),

  // V2 API instance
  v2: createAxiosInstance(process.env.VUE_APP_API_V2_URL),
}

// Shared request interceptor to sync authorization headers across instances
const syncAuthorizationHeaders = config => {
  // Get current auth token from localStorage or wherever you store it
  const token = localStorage.getItem('accessToken')

  // Create a new config object to avoid modifying the parameter
  const newConfig = { ...config }

  // Create a new headers object
  newConfig.headers = { ...config.headers }

  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`
  }

  return newConfig
}

// Apply the interceptor to all instances
Object.values(axiosInstances).forEach(instance => {
  instance.interceptors.request.use(syncAuthorizationHeaders)
})

// Function to update authorization token (call this when user logs in or token refreshes)
const updateAuthToken = token => {
  localStorage.setItem('accessToken', token)
}

// Proxy to allow dynamic base URL selection
const axiosProxy = new Proxy(axiosInstances.main, {
  get(target, prop) {
    // If a method is called, use the current base URL instance
    if (typeof target[prop] === 'function') {
      return target[prop]
    }

    // Allow accessing properties and methods
    return target[prop]
  },

  // Intercept method calls to allow base URL switching
  apply(target, thisArg, argumentsList) {
    return target.apply(thisArg, argumentsList)
  },
})

// Add axios instance to Vue prototype
Vue.prototype.$http = axiosProxy

// Add token update method
Vue.prototype.$http.updateAuthToken = updateAuthToken

// Expose method to switch base URL if needed
Vue.prototype.$http.setBaseURL = (baseUrlKey = 'main') => {
  const instance = axiosInstances[baseUrlKey]
  if (!instance) {
    throw new Error(`No axios instance found for key: ${baseUrlKey}`)
  }

  // Update the proxy target
  Object.keys(axiosProxy).forEach(key => {
    delete axiosProxy[key]
  })
  Object.assign(axiosProxy, instance)

  return instance
}

// Expose all instances for advanced use if needed
Vue.prototype.$http.instances = axiosInstances

export default axiosProxy
