import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableSort: get('postTableSort'),
    tableFilter: get('postTableFilter', []),
    detailData: get('postDetailData'),
    tablePagination: get('postTablePagination'),
    tableKeyword: get('postTableKeyword'),
    userData: [],
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      authorUser_avatar: item.authorUser.avatar,
      authorUser_nickname: item.authorUser.nickname,
      postMedia_image: item.postMedia && item.postMedia.length ? item.postMedia[0].thumbUrl : null,
      postSummary_value: {
        like: item.postSummary.count_like,
        comment: item.postSummary.count_comment,
        share: item.postSummary.count_share,
        id: item.postSummary.post_id,
      },
      postMedia: item.postMedia,
      postMedia_images: item.postMedia.filter(el => el.type === 'image').map(el => el.url),
      postMedia_videos: item.postMedia.filter(el => el.type === 'video').map(el => ({
        image_url: el.thumbUrl,
        url: el.url,
      })),
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('postTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('postTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('postDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('postTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('postTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/posts', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/posts/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/posts/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/posts', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/bo/users/posts/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    getUserData({ commit }, keyword) {
      if (!keyword) {
        commit('SET_USER_DATA', [])
        return null
      }

      return this._vm.$http.get('/v1/bo/users/', {
        params: {
          page: 1,
          perPage: 6,
          keyword,
        },
      })
        .then(({ data }) => {
          const userData = data.data ? data.data.map(item => ({
            value: item.id,
            text: item.nickname || item.email,
            subtitle: item.nickname ? item.email : undefined,
            image: item.avatar,
          })) : []

          commit('SET_USER_DATA', userData)

          return userData
        })
    },
  },
}
