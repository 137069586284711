export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    key: 'description',
    label: 'Description',
    sortable: false,
  },
  {
    key: 'is_available_for_all_user',
    label: 'Available for All User',
    sortable: false,
  },
  {
    key: 'url',
    label: 'Border',
    type: 'image',
    sortable: false,
  },
]
