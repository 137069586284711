export const initialAbility = [
  {
    subject: 'Auth',
    action: 'read',
  },
]

export const superAdminAbility = [
  {
    subject: 'Role',
    action: 'manage',
  },
  {
    subject: 'Dashboard',
    action: 'manage',
  },
  {
    subject: 'Transaction',
    action: 'manage',
  },
  {
    subject: 'DigitalGoods',
    action: 'manage',
  },
  {
    subject: 'Withdrawal',
    action: 'manage',
  },
  {
    subject: 'WithdrawalAccountList',
    action: 'manage',
  },
  {
    subject: 'Order',
    action: 'manage',
  },
  {
    subject: 'Donation',
    action: 'manage',
  },
  {
    subject: 'Subscription',
    action: 'manage',
  },
  {
    subject: 'CreatorPlan',
    action: 'manage',
  },
  {
    subject: 'Voucher',
    action: 'manage',
  },
  {
    subject: 'Event',
    action: 'manage',
  },
  {
    subject: 'User',
    action: 'manage',
  },
  {
    subject: 'Service',
    action: 'manage',
  },
  {
    subject: 'Wallet',
    action: 'manage',
  },
  {
    subject: 'Post',
    action: 'manage',
  },
  {
    subject: 'PostComments',
    action: 'manage',
  },
  {
    subject: 'PostLikes',
    action: 'manage',
  },
  {
    subject: 'PostShares',
    action: 'manage',
  },
  {
    subject: 'Review',
    action: 'manage',
  },
  {
    subject: 'Referral',
    action: 'manage',
  },
  {
    subject: 'CreditPackage',
    action: 'manage',
  },
  {
    subject: 'Game',
    action: 'manage',
  },
  {
    subject: 'Category',
    action: 'manage',
  },
  {
    subject: 'MultipleSection',
    action: 'manage',
  },
  {
    subject: 'MultipleGame',
    action: 'manage',
  },
  {
    subject: 'Highlight',
    action: 'manage',
  },
  {
    subject: 'Banner',
    action: 'manage',
  },
  {
    subject: 'Cms',
    action: 'manage',
  },
  {
    subject: 'ExchangeRate',
    action: 'manage',
  },
  {
    subject: 'AppSetting',
    action: 'manage',
  },
  {
    subject: 'ShowReel',
    action: 'manage',
  },
  {
    subject: 'GamerSpotlight',
    action: 'manage',
  },
  {
    subject: 'TransferWallet',
    action: 'manage',
  },
  {
    subject: 'ActivityLog',
    action: 'manage',
  },
  {
    subject: 'Interest',
    action: 'manage',
  },
  {
    subject: 'ProfileBorder',
    action: 'manage',
  },
  {
    subject: 'Profanity',
    action: 'manage',
  },
  {
    subject: 'ExportResult',
    action: 'read',
  },
  {
    subject: 'UserTransaction',
    action: 'read',
  },
  {
    subject: 'Report',
    action: 'manage',
  },
  {
    subject: 'UsernameTracker',
    action: 'manage',
  },
  {
    subject: 'ProcessingFee',
    action: 'manage',
  },
  {
    subject: 'Topup',
    action: 'manage',
  },
  {
    subject: 'PayPerView',
    action: 'manage',
  },
  {
    subject: 'UpdateContract',
    action: 'manage',
  },
  {
    subject: 'ManualProfileReview',
    action: 'manage',
  },
  {
    subject: 'EditPassword',
    action: 'manage',
  },
]

export const financeAbility = [
  {
    subject: 'Dashboard',
    action: 'read',
  },
  {
    subject: 'Transaction',
    action: 'read',
  },
  {
    subject: 'DigitalGoods',
    action: 'read',
  },
  {
    subject: 'Withdrawal',
    action: 'manage',
  },
  {
    subject: 'WithdrawalAccountList',
    action: 'manage',
  },
  {
    subject: 'Order',
    action: 'read',
  },
  {
    subject: 'Donation',
    action: 'read',
  },
  {
    subject: 'Subscription',
    action: 'manage',
  },
  {
    subject: 'Subscription',
    action: 'read',
  },
  {
    subject: 'CreatorPlan',
    action: 'read',
  },
  {
    subject: 'Voucher',
    action: 'read',
  },
  {
    subject: 'Event',
    action: 'read',
  },
  {
    subject: 'User',
    action: 'read',
  },
  {
    subject: 'Service',
    action: 'read',
  },
  {
    subject: 'Wallet',
    action: 'read',
  },
  {
    subject: 'Post',
    action: 'read',
  },
  {
    subject: 'PostComments',
    action: 'read',
  },
  {
    subject: 'PostLikes',
    action: 'read',
  },
  {
    subject: 'PostShares',
    action: 'read',
  },
  {
    subject: 'Review',
    action: 'read',
  },
  {
    subject: 'Referral',
    action: 'read',
  },
  {
    subject: 'CreditPackage',
    action: 'read',
  },
  {
    subject: 'Game',
    action: 'read',
  },
  {
    subject: 'Category',
    action: 'read',
  },
  {
    subject: 'MultipleSection',
    action: 'read',
  },
  {
    subject: 'MultipleGame',
    action: 'read',
  },
  {
    subject: 'Highlight',
    action: 'read',
  },
  {
    subject: 'Banner',
    action: 'manage',
  },
  {
    subject: 'Cms',
    action: 'read',
  },
  {
    subject: 'ExchangeRate',
    action: 'read',
  },
  {
    subject: 'AppSetting',
    action: 'read',
  },
  {
    subject: 'ShowReel',
    action: 'read',
  },
  {
    subject: 'GamerSpotlight',
    action: 'read',
  },
  {
    subject: 'Interest',
    action: 'read',
  },
  {
    subject: 'ProfileBorder',
    action: 'read',
  },
  {
    subject: 'TransferWallet',
    action: 'manage',
  },
  {
    subject: 'ExportResult',
    action: 'read',
  },
  {
    subject: 'UserTransaction',
    action: 'read',
  },
  {
    subject: 'Report',
    action: 'read',
  },
  {
    subject: 'UsernameTracker',
    action: 'manage',
  },
  {
    subject: 'ProcessingFee',
    action: 'manage',
  },
  {
    subject: 'Topup',
    action: 'manage',
  },
  {
    subject: 'PayPerView',
    action: 'manage',
  },
  {
    subject: 'EditPassword',
    action: 'manage',
  },
]

// TODO: adjust manage to read
export const moderatorAbility = [
  {
    subject: 'Dashboard',
    action: 'manage',
  },
  {
    subject: 'Transaction',
    action: 'manage',
  },
  {
    subject: 'DigitalGoods',
    action: 'read',
  },
  {
    subject: 'Withdrawal',
    action: 'read',
  },
  {
    subject: 'WithdrawalAccountList',
    action: 'read',
  },
  {
    subject: 'Order',
    action: 'manage',
  },
  {
    subject: 'Donation',
    action: 'manage',
  },
  {
    subject: 'Subscription',
    action: 'manage',
  },
  {
    subject: 'CreatorPlan',
    action: 'manage',
  },
  {
    subject: 'Voucher',
    action: 'manage',
  },
  {
    subject: 'Event',
    action: 'manage',
  },
  {
    subject: 'User',
    action: 'read',
  },
  {
    subject: 'Service',
    action: 'manage',
  },
  {
    subject: 'Wallet',
    action: 'manage',
  },
  {
    subject: 'Post',
    action: 'manage',
  },
  {
    subject: 'PostComments',
    action: 'manage',
  },
  {
    subject: 'PostLikes',
    action: 'manage',
  },
  {
    subject: 'PostShares',
    action: 'manage',
  },
  {
    subject: 'Review',
    action: 'manage',
  },
  {
    subject: 'Referral',
    action: 'manage',
  },
  {
    subject: 'CreditPackage',
    action: 'read',
  },
  {
    subject: 'Game',
    action: 'manage',
  },
  {
    subject: 'Category',
    action: 'manage',
  },
  {
    subject: 'MultipleSection',
    action: 'manage',
  },
  {
    subject: 'MultipleGame',
    action: 'manage',
  },
  {
    subject: 'Highlight',
    action: 'manage',
  },
  {
    subject: 'Banner',
    action: 'manage',
  },
  {
    subject: 'Cms',
    action: 'manage',
  },
  {
    subject: 'ExchangeRate',
    action: 'manage',
  },
  {
    subject: 'AppSetting',
    action: 'read',
  },
  {
    subject: 'ShowReel',
    action: 'manage',
  },
  {
    subject: 'GamerSpotlight',
    action: 'manage',
  },
  {
    subject: 'Interest',
    action: 'read',
  },
  {
    subject: 'ProfileBorder',
    action: 'read',
  },
  {
    subject: 'Profanity',
    action: 'manage',
  },
  {
    subject: 'UserTransaction',
    action: 'read',
  },
  {
    subject: 'UsernameTracker',
    action: 'manage',
  },
  {
    subject: 'ProcessingFee',
    action: 'manage',
  },
  {
    subject: 'Topup',
    action: 'manage',
  },
  {
    subject: 'PayPerView',
    action: 'manage',
  },
  {
    subject: 'ManualProfileReview',
    action: 'manage',
  },
  {
    subject: 'EditPassword',
    action: 'manage',
  },
]

// TODO: adjust manage to read
export const marketerAbility = [
  {
    subject: 'Dashboard',
    action: 'manage',
  },
  {
    subject: 'Transaction',
    action: 'manage',
  },
  {
    subject: 'DigitalGoods',
    action: 'read',
  },
  {
    subject: 'Withdrawal',
    action: 'read',
  },
  {
    subject: 'WithdrawalAccountList',
    action: 'read',
  },
  {
    subject: 'Order',
    action: 'read',
  },
  {
    subject: 'Donation',
    action: 'manage',
  },
  {
    subject: 'Subscription',
    action: 'read',
  },
  {
    subject: 'CreatorPlan',
    action: 'manage',
  },
  {
    subject: 'Voucher',
    action: 'manage',
  },
  {
    subject: 'Event',
    action: 'manage',
  },
  {
    subject: 'User',
    action: 'read',
  },
  {
    subject: 'Service',
    action: 'manage',
  },
  {
    subject: 'Wallet',
    action: 'manage',
  },
  {
    subject: 'Post',
    action: 'read',
  },
  {
    subject: 'PostComments',
    action: 'read',
  },
  {
    subject: 'PostLikes',
    action: 'read',
  },
  {
    subject: 'PostShares',
    action: 'read',
  },
  {
    subject: 'Review',
    action: 'read',
  },
  {
    subject: 'Referral',
    action: 'manage',
  },
  {
    subject: 'CreditPackage',
    action: 'read',
  },
  {
    subject: 'Game',
    action: 'manage',
  },
  {
    subject: 'Category',
    action: 'manage',
  },
  {
    subject: 'MultipleSection',
    action: 'manage',
  },
  {
    subject: 'MultipleGame',
    action: 'manage',
  },
  {
    subject: 'Highlight',
    action: 'manage',
  },
  {
    subject: 'Banner',
    action: 'manage',
  },
  {
    subject: 'Cms',
    action: 'manage',
  },
  {
    subject: 'ExchangeRate',
    action: 'manage',
  },
  {
    subject: 'AppSetting',
    action: 'read',
  },
  {
    subject: 'ShowReel',
    action: 'manage',
  },
  {
    subject: 'GamerSpotlight',
    action: 'manage',
  },
  {
    subject: 'Interest',
    action: 'read',
  },
  {
    subject: 'ProfileBorder',
    action: 'manage',
  },
  {
    subject: 'Profanity',
    action: 'manage',
  },
  {
    subject: 'UserTransaction',
    action: 'read',
  },
  {
    subject: 'Report',
    action: 'read',
  },
  {
    subject: 'UsernameTracker',
    action: 'manage',
  },
  {
    subject: 'ProcessingFee',
    action: 'manage',
  },
  {
    subject: 'Topup',
    action: 'manage',
  },
  {
    subject: 'PayPerView',
    action: 'manage',
  },
  {
    subject: 'UpdateContract',
    action: 'manage',
  },
  {
    subject: 'EditPassword',
    action: 'manage',
  },
]

export const adminAbility = [
  {
    subject: 'Dashboard',
    action: 'manage',
  },
  {
    subject: 'Transaction',
    action: 'manage',
  },
  {
    subject: 'DigitalGoods',
    action: 'manage',
  },
  {
    subject: 'Withdrawal',
    action: 'manage',
  },
  {
    subject: 'WithdrawalAccountList',
    action: 'manage',
  },
  {
    subject: 'Order',
    action: 'manage',
  },
  {
    subject: 'Donation',
    action: 'manage',
  },
  {
    subject: 'Subscription',
    action: 'manage',
  },
  {
    subject: 'CreatorPlan',
    action: 'manage',
  },
  {
    subject: 'Voucher',
    action: 'manage',
  },
  {
    subject: 'Event',
    action: 'manage',
  },
  {
    subject: 'User',
    action: 'manage',
  },
  {
    subject: 'Service',
    action: 'manage',
  },
  {
    subject: 'Wallet',
    action: 'manage',
  },
  {
    subject: 'Post',
    action: 'manage',
  },
  {
    subject: 'PostComments',
    action: 'manage',
  },
  {
    subject: 'PostLikes',
    action: 'manage',
  },
  {
    subject: 'PostShares',
    action: 'manage',
  },
  {
    subject: 'Review',
    action: 'manage',
  },
  {
    subject: 'Referral',
    action: 'manage',
  },
  {
    subject: 'CreditPackage',
    action: 'manage',
  },
  {
    subject: 'Game',
    action: 'manage',
  },
  {
    subject: 'Category',
    action: 'manage',
  },
  {
    subject: 'MultipleSection',
    action: 'manage',
  },
  {
    subject: 'MultipleGame',
    action: 'manage',
  },
  {
    subject: 'Highlight',
    action: 'manage',
  },
  {
    subject: 'Banner',
    action: 'manage',
  },
  {
    subject: 'Cms',
    action: 'manage',
  },
  {
    subject: 'ExchangeRate',
    action: 'manage',
  },
  {
    subject: 'AppSetting',
    action: 'manage',
  },
  {
    subject: 'ShowReel',
    action: 'manage',
  },
  {
    subject: 'GamerSpotlight',
    action: 'manage',
  },
  {
    subject: 'ActivityLog',
    action: 'manage',
  },
  {
    subject: 'TransferWallet',
    action: 'manage',
  },
  {
    subject: 'Interest',
    action: 'manage',
  },
  {
    subject: 'ProfileBorder',
    action: 'manage',
  },
  {
    subject: 'Profanity',
    action: 'manage',
  },
  {
    subject: 'ExportResult',
    action: 'read',
  },
  {
    subject: 'UserTransaction',
    action: 'manage',
  },
  {
    subject: 'Report',
    action: 'manage',
  },
  {
    subject: 'UsernameTracker',
    action: 'manage',
  },
  {
    subject: 'ProcessingFee',
    action: 'manage',
  },
  {
    subject: 'Topup',
    action: 'manage',
  },
  {
    subject: 'PayPerView',
    action: 'manage',
  },
  {
    subject: 'UpdateContract',
    action: 'manage',
  },
  {
    subject: 'ManualProfileReview',
    action: 'manage',
  },
  {
    subject: 'EditPassword',
    action: 'manage',
  },
]

export const customerSupportAbility = [
]

export const customerAbility = [
]

export const _ = undefined
